import { Item, ItemDto } from "../dtos";

export const toItem = (dto: ItemDto): Item => {
  const item: Item = {
    pk: dto.pk,
    sk: dto.sk,
    name: dto.itemName,
    aisleId: dto.itemAisleId,
    shoppingAmount: dto.itemShoppingAmount,
    shoppingUnit: dto.itemShoppingUnit,
    shoppingListAmount: dto.itemShoppingListAmount,
    isIngredient: dto.itemIsIngredient,
    isStockItem: dto.itemIsStockItem,
    isInShoppingCart: dto.itemIsInShoppingCart,
    isFavourite: dto.itemIsFavourite
  };

  return item;
}

export const toDto = (item: Item) => {
  const dto: ItemDto = {
    pk: item.pk,
    sk: item.sk,
    itemName: item.name,
    itemAisleId: item.aisleId,
    itemShoppingAmount: item.shoppingAmount,
    itemShoppingUnit: item.shoppingUnit,
    itemShoppingListAmount: item.shoppingListAmount,
    itemIsIngredient: item.isIngredient,
    itemIsStockItem: item.isStockItem,
    itemIsInShoppingCart: item.isInShoppingCart,
    itemIsFavourite: item.isFavourite
  };

  return dto;
}